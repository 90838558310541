import React from "react";
import "../styles/information.css";

export const CarrierUserAndPasswordsPage = () => {
  const carriers = [
    {
      carrier: "HAPAG",
      website:
        "https://www.hapag-lloyd.com/en/online-business/track/track-by-container-solution.html",
      email: "chris@shipdei.com",
      user: "DIRECTEXP",
      password: "route66109",
    },
    {
      carrier: "HMM",
      website: "https://www.hmm21.com/e-service/auth/login.do",
      email: "chris@shipdei.com",
      user: "DIREXPINC",
      password: "route66109",
    },
    {
      carrier: "HAPAG",
      website:
        "https://www.hapag-lloyd.com/en/online-business/track/track-by-container-solution.html",
      email: "chris@shipdei.com",
      user: "chris@shipdei.com",
      password: "Route662109!",
    },
    {
      carrier: "EVERGREEN",
      website: "https://www.shipmentlink.com/tam1/jsp/TAM1_Login.jsp?lang=en",
      email: "shipping@shipdei.com",
      user: "shipping@shipdei.com",
      password: "xGW9PDdfF3",
    },
    {
      carrier: "ONELINE",
      website: "https://www.one-line.com/en",
      email: "operations@shipdei.com",
      user: "DEI109",
      password: "route66109$",
    },
    {
      carrier: "ONE LINE",
      website: "https://www.one-line.com/en",
      email: "chris@shipdei.com",
      user: "DIRECT1EXP",
      password: "Route109!",
    },
    {
      carrier: "YANG MING",
      website:
        "https://www.yangming.com/e-service/member_area/member_login.aspx",
      email: "chris@shipdei.com",
      user: "DIRECTEXPRCA",
      password: "DIRECTSUPPORTusa109!",
    },
    {
      carrier: "MAERSK",
      website: "https://www.maersk.com",
      email: "chris@shipdei.com",
      user: "directexp",
      password: "Carob230!",
    },
    {
      carrier: "ANL",
      website: "https://www.anl.com.au/my-anl",
      email: "chris@shipdei.com",
      user: "chris@shipdei.com",
      password: "Route66Rambo11209!",
    },
    {
      carrier: "OOCL",
      website: "https://moc.oocl.com/maintenance.htm",
      email: "shipping@shipdei.com",
      user: "DIRECTEXP1",
      password: "Route66109",
    },
    {
      carrier: "MSC",
      website: "https://www.mymsc.com/mymsc/",
      email: "shipping@shipdei.com",
      user: "shipping@shipdei.com",
      password: "route66109*",
    },
  ];

  return (
    <div className="extraShippingInformationContainer">
      <h2>Carrier User And Passwords</h2>
      <div className="extraShippingInformationTable">
        <table>
          <thead>
            <tr>
              <th>Carrier</th>
              <th>Website</th>
              <th>Email</th>
              <th>User</th>
              <th>Password</th>
            </tr>
          </thead>
          <tbody>
            {carriers.map((carrier, index) => (
              <tr key={index}>
                <td>{carrier.carrier}</td>
                <td>
                  {" "}
                  <a
                    href={carrier.website}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Open Website
                  </a>
                </td>
                <td>{carrier.email}</td>
                <td>{carrier.user}</td>
                <td>{carrier.password}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
