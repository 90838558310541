import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "../styles/documentsRequiredForExport.css";

export const ImportCBPEntryPage = () => {
  const files = [
    {
      name: "Import CBP Entry Summary Sample",
      link: "https://drive.google.com/file/d/1U6dTJKMULxf6pBSD8Yua92VgFfpQ7-Oy/view?usp=sharing",
    },
  ];

  return (
    <div className="container">
      <h1>Us Customs Entry Summary Sample</h1>

      <div className="documentsInformationContainer">
        <table className="documentsInformationTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                <td>
                  <a
                    href={file.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to download
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
