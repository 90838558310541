import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "../styles/documentsRequiredForExport.css";

export const AtaCarnetPage = () => {
  const content = [
    {
      title: <b>A.T.A. Carnet - US Customs Clearance Procedure</b>,
      details: [
        "Prepare and make copies of Carnet as per attached sample.",
        "Fill out Carnet fields as per attached sample.",
        "Make copies of Carnet after all proper fields have been filled out.",
        "File AES via computer system / Cargo Manager.",
        "Prepare US Customs cover letter.",
        "Attach a copy of the master booking to US Customs cover letter (see attached sample).",
        <b>Submit the following to US Customs ATA Carnet Division:</b>,
        "US Customs cover letter with AES and ITN response.",
        "Copy of master booking.",
        "Original and properly completed, filled-out ATA Carnet.",
      ],
    },
  ];

  const files = [
    {
      name: "Sample Document",
      link: "https://drive.google.com/file/d/1V6V9pNe6_faPK_cREDAYenpQYKRElFkE/view?usp=sharing",
    },
  ];

  const [activeSections, setActiveSections] = useState([true]);

  const toggleAccordion = (index) => {
    setActiveSections((prevActiveSections) => {
      const newActiveSections = [...prevActiveSections];
      newActiveSections[index] = !newActiveSections[index];
      return newActiveSections;
    });
  };

  return (
    <div className="container">
      <h1>A.T.A. Carnet - US Customs Clearance Procedure</h1>
      <p>
        Please review the following procedure to ensure all necessary
        documentation is prepared and submitted correctly.
      </p>

      {content.map((section, index) => (
        <div key={index} className="accordion-item">
          <button
            onClick={() => toggleAccordion(index)}
            className={`accordion-title ${
              activeSections[index] ? "active" : ""
            }`}
            aria-expanded={activeSections[index]}
          >
            {section.title}
            <span className="arrow">
              {activeSections[index] ? (
                <RiArrowDropUpLine size={24} />
              ) : (
                <RiArrowDropDownLine size={24} />
              )}
            </span>
          </button>
          {activeSections[index] && (
            <div className="accordion-content">
              {section.details.map((item, idx) => (
                <div key={idx} className="accordion-content-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div className="documentsInformationContainer">
        <table className="documentsInformationTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                <td>
                  <a
                    href={file.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to download
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
