import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "../styles/documentsRequiredForExport.css";
export const InsuranceHhgUniriscPage = () => {
  const content = [
    {
      title: <b>Unirisc Online Guide for Shippers</b>,
      details: [
        "Unirisc has been diligently working on an innovative Online Guide, complete with valuable information and instructional videos covering policy details, documentation preparation, and claims filing procedures. This online resource is intended to replace the current insurance guide being distributed to your shippers.",
        "Within the guide, there's a convenient 'Download Now' button for shippers to effortlessly obtain the necessary documents for completion and return. These documents will be fillable PDFs, ensuring ease of use for recipients. However, in the event they encounter issues with PDFs, they can print the documents and return them via scan.",
        <b>*Access to the Guide:*</b>,
        "The attached one-page document can be shared with your shipper to grant access to the online guide. Or you can simply send them a copy of the link below:",
        "Please be sure that all move coordinators in your office receive this email and attachment, so that the new online guide can begin being used immediately.",
        "If you have any questions or need further assistance, please don't hesitate to reach out.",
      ],
    },
  ];
  const files = [
    {
      name: "Unirisc Online Guide Access Document",
      link: "https://portal.unirisc.com/hhg17/",
    },
  ];

  const websites = [
    {
      website: "https://insure.unirisc.com/Default.aspx",
      username: "chrisdei",
      password: "Route109DEI$#%",
    },
  ];

  const [activeSections, setActiveSections] = useState([true]);

  const toggleAccordion = (index) => {
    setActiveSections((prevActiveSections) => {
      const newActiveSections = [...prevActiveSections];
      newActiveSections[index] = !newActiveSections[index];
      return newActiveSections;
    });
  };

  return (
    <div className="container">
      <h1>Unirisc Online Guide for Shippers</h1>
      <p>
        Please review the following information about the new online guide for
        shippers. This resource is designed to provide comprehensive support for
        policy details, documentation preparation, and claims filing procedures.
      </p>

      {content.map((section, index) => (
        <div key={index} className="accordion-item">
          <button
            onClick={() => toggleAccordion(index)}
            className={`accordion-title ${
              activeSections[index] ? "active" : ""
            }`}
            aria-expanded={activeSections[index]}
          >
            {section.title}
            <span className="arrow">
              {activeSections[index] ? (
                <RiArrowDropUpLine size={24} />
              ) : (
                <RiArrowDropDownLine size={24} />
              )}
            </span>
          </button>
          {activeSections[index] && (
            <div className="accordion-content">
              {section.details.map((item, idx) => (
                <div key={idx} className="accordion-content-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <div className="documentsInformationContainer">
        <table className="documentsInformationTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                <td>
                  <a
                    href={file.link}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to access the guide
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="documentsInformationTable">
          <thead>
            <tr>
              <th>Website</th>
              <th>User</th>
              <th>Password</th>
            </tr>
          </thead>
          <tbody>
            {websites.map((website, index) => (
              <tr key={index}>
                <td>
                  <a
                    href={website.website}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    UNIRISC Clientside
                  </a>
                </td>
                <td>{website.username}</td>
                <td>{website.password}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
